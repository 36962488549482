.sidenav-toggle-comp {
  .burger {
    position: relative;
    z-index: 101;

    height: 2em;
    padding-top: 0.3em;

    .navbar-toggle {
      position: relative;

      display: block;
      width: 1.7em;
      padding: 4px;

      background-color: transparent;
      border: 0 none;
      border-radius: 0;
      outline: 0 none;

      .icon-bar {
        position: absolute;
        right: 0;

        display: block;
        height: 3px;

        background-color: black;
        opacity: 1;

        will-change: "opacity, transform, width, top";

        &:nth-child(1) {
          top: 0;

          width: 100%;

          transition: top 0.1s ease-out 0s, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        }

        &:nth-child(2) {
          top: 0.5em;

          width: 100%;

          transition: opacity 0.1s ease-out 0.12s;
        }

        &:nth-child(3) {
          top: 1em;

          width: 100%;

          transition: top 0.1s ease-out 0s, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        }
      }

      &.expanded {
        .icon-bar {
          width: 100%;

          &:nth-child(1) {
            top: 7px;

            transform: rotate(225deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            top: 7px;

            transform: rotate(-225deg);
          }
        }
      }
    }
  }

  .flying-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    outline: 0 none;
    transform: translateX(100vw);
    visibility: hidden;

    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

    &.show {
      outline: 0 none;
      transform: translateX(0vw);
      visibility: visible;
    }

    .menu {
      position: relative;
      right: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70vw;
      max-width: 300px;
      height: 100vh;
      margin-left: auto;
      padding-top: $header-height-sm;
      padding-bottom: 1em;

      background-color: white;
      box-shadow: 8px 0.5px 10px 5px rgba(0, 0, 0, 0.75);

      @include media-breakpoint-up(md) {
        padding-top: $header-height-md;
        padding-bottom: 2em;
      }
    }
  }
}
