/** Also whitelist any new elements at postcss.config.js */
.cms-content {
  display: block;

  img {
    display: block;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  table {
    @extend .table, .table-striped, .table-bordered;

    margin: 1rem 0 2rem;

    p,
    label {
      margin-bottom: 0;
    }
  }

  blockquote {
    @extend .blockquote;
  }

  iframe {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 1rem auto;
    overflow: hidden;
  }
}
