.horizontal-hid-layout-hoc {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
  align-items: center;
  justify-items: center;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
  }

  @include media-breakpoint-up(xl) {
    gap: 4em;
  }

  @include media-breakpoint-up(x2l) {
    gap: 5em;
  }

  .hid-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
      }
    }

    &.mobile {
      display: flex;
      justify-content: flex-start;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    > img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .hid-detail-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    width: 100%;

    .title {
      margin-bottom: 0;

      font-weight: 600;
      font-size: 1.3em;

      @include media-breakpoint-up(xl) {
        font-size: 1.5em;
      }
    }

    label {
      display: block;
      margin-bottom: 1em;

      font-weight: 600;
    }

    p {
      line-height: 1.6;
    }
  }
}
