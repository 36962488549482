@import "../../../../styles/colors";
@import "~bootstrap/scss/bootstrap-grid";

.header-search-comp {
  position: relative;
  z-index: 1;

  display: block;
  padding: 0.3em 1em;

  > .wrapper {
    position: relative;

    display: flex;
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 500px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 700px;
    }

    .form {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;

      border-bottom: 1px solid $corduroy;

      .content-search-input {
        width: 100%;

        border: 0 none;
        outline: 0 none;
        box-shadow: none;
      }
    }

    .search-results {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;

      display: block;
      width: 100%;
      min-height: 100px;
      max-height: calc(100vh - 150px);
      overflow: hidden;
      overflow-y: auto;

      font-size: 0.9em;
      letter-spacing: -0.016em;
      white-space: nowrap;

      background-color: white;
      border-top: none;
      border-bottom-right-radius: 1em;
      border-bottom-left-radius: 1em;

      .xy-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100px;

        color: $corduroy;

        > p {
          margin: 0;
        }
      }

      .search-result-section {
        padding: 1em 2em;

        ~ .search-result-section {
          border-top: 1px solid lighten($salt-box, 50%);
        }

        .title {
          color: $corduroy;
          font-size: 0.8em;
          text-transform: uppercase;
        }

        .list {
          margin-bottom: 0;
          padding-top: 5px;
          padding-left: 0;

          list-style: none;
        }

        .link {
          display: block;
          padding: 0 1em;

          line-height: 1.8;
          text-transform: capitalize;

          &:hover,
          &:focus {
            color: $corduroy;
            text-decoration: none;

            background-color: lighten($salt-box, 55%);
          }
        }
      }
    }
  }
}
