.locations-page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  $content-side-gap: 1em;

  .locations-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    padding-bottom: $content-side-gap;
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr minmax(0, 220px);

    .locations-list {
      padding-right: $content-side-gap;
      padding-bottom: 0;
    }

    .popular-locations-list {
      min-height: 100%;
      padding-top: 0;
      padding-left: $content-side-gap;

      border-top: 0 none;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
