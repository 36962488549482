.service-page {
  width: 100%;

  .services-wrapper {
    display: block;
  }

  section.service {
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;

    border-bottom: 1px solid $corduroy;

    &:first-child {
      padding-top: 0;
    }
  }

  .service-links {
    text-transform: capitalize;
  }
}
