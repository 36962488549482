.simple-collapsible-hoc {
  .collapsible-link {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;

    font-weight: 600;

    font-size: 1.25em;
    line-height: 1.5;
    text-transform: capitalize;

    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    > i {
      margin-left: 0.5em;

      text-align: center;
    }
  }

  .collapsible-container {
    margin-top: 1em;

    @include media-breakpoint-up(md) {
      margin-top: 1.5em;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 2em;
    }
  }
}
