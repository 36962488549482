.simple-expert-link-card {
  display: block;
  width: 100%;
  margin-bottom: 0.5em;
  overflow: hidden;

  text-align: left;

  @include media-breakpoint-up(lg) {
    margin-bottom: 1em;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 2em;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1em;
    }

    .avatar {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 311px;
    }
  }

  .display-name {
    margin-bottom: 0;

    color: $corduroy;
    font-size: 1em;

    @include media-breakpoint-up(lg) {
      font-size: 1.3em;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.5em;
    }
  }

  .position {
    margin-bottom: 0;

    color: $kabul;
    font-size: 0.8em;

    @include media-breakpoint-up(lg) {
      font-size: 0.9em;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1em;
    }
  }
}
