.service-detail-page {
  nav {
    display: block;

    a.link-back {
      > i {
        margin-right: 0.5em;
      }
    }
  }

  .title {
    text-transform: capitalize;
  }

  .service-wrapper {
    display: block;
    margin-bottom: 3rem;

    .cms-content {
      a {
        color: $azure-radiance;
        text-decoration: underline;
      }
    }
  }

  section.abstract-section {
    display: block;
    padding-top: 2.5em;
    padding-bottom: 2.5em;

    background-color: $athens-gray;

    .abstract-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      ul {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        margin: 0;
        padding: 0;

        list-style-position: inside;

        li {
          display: list-item;
          max-width: 250px;
        }

        @include media-breakpoint-up(md) {
          grid-template-columns: 1fr 1fr;
          gap: 1em 250px;
        }
      }
    }
  }

  section.rel-section {
    font-size: 0.9em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1.5em;

      font-weight: 700;
      font-size: 1.3em;
      text-transform: capitalize;
    }

    ~ section.rel-section {
      margin-top: 5em;
    }
  }
}
