.dropdown.standard {
  .form-control > span {
    text-transform: capitalize;
  }
  button.dropdown-toggle {
    overflow: hidden;

    color: $input-placeholder-color;
    text-align: left;

    &:after {
      position: absolute;
      top: 45%;
      right: 20px;

      margin-left: 0;

      vertical-align: unset;
    }

    &.active {
      color: #fff;
    }

    i.fa-chevron-down {
      transition: 0.1s ease-in-out;

      will-change: transform;
    }
  }

  .dropdown-toggle {
    img.svg,
    i.fa {
      margin-right: 8px;
    }
  }

  &.show {
    .dropdown-toggle:after {
      border-top: 0;
      border-bottom: 0.3em solid;
    }

    button.dropdown-toggle {
      i.fa-chevron-down {
        transform: rotateZ(180deg);
      }
    }
  }

  .dropdown-divider {
    border-color: $input-border-color;
  }

  .dropdown-menu {
    min-width: 100%;
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;

    background: #fff;
    background: rgba(#fff, 0.9);
    border: 1px solid $input-border-color;

    .dropdown-item {
      color: $salt-box;
      line-height: 2em;

      img.svg {
        display: inline-block;
        max-width: 1em;
        max-height: 1em;
      }

      img.svg,
      i.fa {
        margin-right: 8px;
      }

      &:hover,
      &:focus {
        color: $corduroy;

        background: $athens-gray;
        background: rgba($athens-gray, 0.9);

        svg {
          fill: $corduroy;
        }
      }
    }
  }
}

.modal.standard {
  .modal-dialog {
    max-width: 550px;

    .modal-content {
      background-color: $athens-gray;
      border: 1px solid $corduroy;

      .modal-header {
        align-items: center;
        padding: 0.5rem 1rem;

        border: 0 none;
      }

      .close {
        margin: 0;
        padding: 0;

        color: $salt-box;
        font-size: 2rem;
        text-shadow: none;

        border: 0 none;
        outline: 0 none;
      }

      .modal-body {
        padding-bottom: 2rem;
      }
    }
  }
}
