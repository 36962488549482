.common-form {
  > .wrapper {
    max-width: 550px;
  }

  form {
    .form-group ~ .form-group,
    .row-form-group .form-group {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        margin-top: 2rem;
      }
    }

    .form-group {
      > .label-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        > label {
          width: 100%;
        }
      }
    }
  }
}
