.simple-expert-detail-card {
  .first-name,
  .last-name {
    text-transform: capitalize;
  }

  .credentials {
    text-transform: uppercase;
  }

  .socials {
    display: flex;
    flex-direction: row;
    gap: 1em;

    a.social-link {
      display: block;

      font-weight: 600;
      text-decoration: underline;

      &:hover {
        color: darken($corduroy, 15%);
      }
    }
  }
}
