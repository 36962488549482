.custom-uri-page {
  display: block;

  .container {
    > .cms-comp {
      &:not(:first-child) {
        margin-top: 5em;
      }
  
      &:not(:last-child) {
        margin-bottom: 5em;
      }
    }

  }
}