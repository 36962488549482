/**
* Animations
*/
@keyframes nav-curtain-anim {
  0% {
    opacity: 0;

    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;

    pointer-events: none;
  }

  100% {
    opacity: 1;

    pointer-events: auto;
  }
}

@keyframes search-curtain-anim {
  0% {
    transform: translateX(30%);
    opacity: 0;

    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;

    pointer-events: none;
  }

  100% {
    transform: translateX(0);
    opacity: 1;

    pointer-events: auto;
  }
}

@keyframes search-curtain-anim-reverse {
  0% {
    transform: translateX(0);
    opacity: 1;

    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;

    pointer-events: auto;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;

    pointer-events: none;
  }
}

/**
* Watch _variables.scss for any tweaks to header's dimension
*/
.header-comp {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  width: 100%;

  background-color: white;
  box-shadow: 0px -0.5em 1em 0px rgba(0, 0, 0, 0.75);

  ~ main {
    margin-top: $header-height-sm; // Header height

    @include media-breakpoint-up(md) {
      margin-top: $header-height-md; // Header height
    }
  }

  .main-navbar {
    display: flex;
    height: $header-height-sm;

    transition: height 0.3s;

    will-change: height;

    @include media-breakpoint-up(md) {
      height: $header-height-md; // Header height
    }

    .navbar-brand {
      display: block;
      height: 100%;

      .logo {
        display: none;
        height: 100%;
        object-fit: contain;

        @include media-breakpoint-down(md) {
          // 1440px
          &.full {
            display: inline-block;
          }
        }

        @include media-breakpoint-up(x2l) {
          // 1440px
          &.full {
            display: inline-block;
          }
        }

        @include media-breakpoint-between(lg, xl) {
          // 992px and 1440px
          &.only {
            display: inline-block;
          }
        }
      }
    }

    .btn-round {
      width: 3.5em;
      height: 3.5em;

      font-size: 0.8em;

      @include media-breakpoint-up(xl) {
        font-size: 0.9em;
      }

      @include media-breakpoint-up(x2l) {
        font-size: 1em;
      }
    }

    .sidenav-toggle-comp {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .header-search-comp {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;

      opacity: 0;

      pointer-events: none;
    }
  }

  // Animation variables
  $total-curtains: 14;

  &.searchshow {
    .main-navbar {
      .nav-curtain {
        animation: nav-curtain-anim 0.4s reverse both;
      }

      .sidenav {
        display: none;
      }

      .header-search-comp {
        animation: search-curtain-anim 0.4s both;
        animation-delay: 0.2s;

        pointer-events: auto;
      }
    }
  }

  &.searchhide {
    .main-navbar {
      .nav-curtain {
        animation: nav-curtain-anim 0.4s both;
        animation-delay: 0.2s;
      }

      .header-search-comp {
        animation: search-curtain-anim 0.4s reverse both;

        pointer-events: auto;
      }
    }
  }

  &.searchopen {
    .main-navbar {
      .nav-curtain {
        display: none;

        pointer-events: none;
      }

      .header-search-comp {
        visibility: visible;
        opacity: 1;

        pointer-events: auto;
      }
    }
  }

  &.scrolled {
    .main-navbar {
      height: $header-height-sm / 1.25;

      @include media-breakpoint-up(md) {
        height: $header-height-md / 1.5; // Header height
      }
    }

    .sidenav-toggle-comp .burger .navbar-toggle .icon-bar {
      height: 2px;
    }
  }

  .nav-list {
    > .nav-item {
      .nav-link {
        white-space: nowrap;
      }
    }
  }

  .btn-round {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-radius: 50%;

    > span {
      display: none;
    }
  }

  .nav-item.active {
    a {
      color: darken($corduroy, 90%);
    }
  }

  .nav-search {
    justify-content: flex-end;
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
  }

  ul.navbar-nav {
    > .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > li {
      .btn {
      }

      &:not(:last-child) {
        margin-right: 0.75em;

        @include media-breakpoint-up(md) {
          margin-right: 1em;
        }

        @include media-breakpoint-up(xl) {
          margin-right: 1.5em;
        }
      }
    }
  }
}
