.footer-comp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 110px;
  padding: 2rem 0;

  color: $white;

  background-color: $corduroy;

  @include media-breakpoint-up(md) {
    min-height: 120px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 150px;
  }

  .nav-directory {
    font-size: 0.9rem;

    .list-group {
      .list-group-item {
        padding: 0.25rem 0.5rem;

        border-bottom: 0 none;
      }
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: $white;
      }
    }
  }

  .brand-navbar {
    display: flex;
    flex-flow: column-reverse nowrap;
    padding: 0.25rem;

    @include media-breakpoint-up(sm) {
      flex-flow: row nowrap;
    }

    .nav-social {
      margin-bottom: 0.5em;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }
  }

  .nav-social {
    .btn {
      padding: 0 1em;

      color: white;
      font-size: 1.2em;

      &:hover {
        color: darken(white, 15%);
      }
    }
  }
}
