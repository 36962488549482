.teams-page {
  font-size: 1rem;

  .filter-container-desktop,
  .filter-container-mobile {
    label {
      color: $salt-box;
      font-size: 0.9em;
    }

    .filter-dropdown {
      width: 210px;
    }
  }

  .filter-container-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3em;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .filter-container-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 5em;
    }
  }

  .grid-container {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, 130px);
    gap: 1em;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(auto-fit, 200px);
      gap: 1.5em;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fit, 260px);
      gap: 2em;
    }

    @include media-breakpoint-up(x2l) {
      grid-template-columns: repeat(auto-fit, 300px);
    }

    @include media-breakpoint-up(x3l) {
      gap: 2em 4em;
    }
  }
}

.teams-page-modal-filter {
  .modal-body {
    label {
      color: $salt-box;
      font-size: 0.9em;
    }

    .filter-wrapper ~ .filter-wrapper {
      margin-top: 1.5rem;
    }
  }
}
