.team-detail-page {
  > .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3em;

    @include media-breakpoint-up(lg) {
      gap: 5em;
    }
  }

  nav {
    display: block;

    a.link-back {
      > i {
        margin-right: 0.5em;
      }
    }
  }

  section {
    .title {
      display: block;
      margin-bottom: 1em;

      color: $kabul;
      font-weight: 600;
      font-size: 1.3em;
    }

    a.link {
      display: block;
      overflow: hidden;

      font-weight: 600;
      line-height: 1.5;
    }
  }

  .blogs {
    .blog-link {
      display: flex;
      align-items: center;
      padding: 1em 0;

      .img-blog {
        display: block;
        max-width: 100%;
        height: auto;
        margin-right: 1em;
      }

      .blog-body {
        display: block;
        width: 100%;
      }

      ~ .blog-link {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
