.breadcrumbs-comp {
  .breadcrumb {
    padding: 1rem 0;

    background-color: transparent;

    .breadcrumb-item {
      text-transform: capitalize;

      + .breadcrumb-item {
        &:before {
          content: "→";
        }
      }
    }
  }
}
