.faqs-page,
.service-faqs-section {
  width: 100%;

  .services-wrapper {
  }

  section.faq {
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;

    border-bottom: 1px solid $corduroy;

    &:first-child {
      padding-top: 0;
    }

    .collapsible-link {
      text-transform: initial;
    }
  }
}
