.location-detail-page {
  > .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3em;

    @include media-breakpoint-up(lg) {
      gap: 5em;
    }
  }

  nav {
    display: block;

    a.link-back {
      > i {
        margin-right: 0.5em;
      }
    }
  }

  address {
    display: block;
    margin-bottom: 1em;

    font-style: normal;
  }

  .map-container {
    width: 100%;
    height: 300px;

    @include media-breakpoint-up(lg) {
      height: 350px;
    }

    @include media-breakpoint-up(xl) {
      height: 400px;
    }

    .map {
      height: 100%;
    }
  }

  .content {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  section {
    .title {
      display: block;
      margin-bottom: 1em;

      color: $kabul;
      font-weight: 600;
      font-size: 1.3em;
    }

    a.link {
      display: block;
      overflow: hidden;

      font-weight: 600;
      line-height: 1.5;
    }
  }

  .business-hours-list {
    strong {
      display: inline-block;
      width: 150px;
    }
  }

  .experts-wrapper {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, 110px);
    gap: 1em;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(auto-fit, 140px);
      gap: 1.5em;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fit, 190px);
      gap: 2em;
    }

    @include media-breakpoint-up(x2l) {
      grid-template-columns: repeat(auto-fit, 245px);
    }

    @include media-breakpoint-up(x3l) {
      gap: 2em 4em;
    }
  }
}
