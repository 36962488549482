.layout-v1 {
  position: relative;

  .layout-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 159px;
    max-height: 318px;

    color: white;

    background-image: url("../../../public/static/images/layout/layout-hero-1440.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include media-breakpoint-up(md) {
      height: 250px;
    }

    @include media-breakpoint-up(xl) {
      height: 318px;
    }

    .title-container {
      display: block;
      max-width: 550px;

      text-align: center;

      .title {
        margin-bottom: 0;

        font-weight: 600;
        font-size: 1.3em;

        @include media-breakpoint-up(xl) {
          font-size: 1.5em;
        }
      }

      .tagline {
        margin-top: 0.5em;
        margin-bottom: 0;

        font-size: 0.9em;

        @include media-breakpoint-up(xl) {
          font-size: 1em;
        }
      }
    }
  }

  .layout-container {
    width: 100%;
    min-height: 70vh;

    &:not(.no-padding) {
      padding-top: 2rem;
      padding-bottom: 2rem;

      @include media-breakpoint-up(md) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
      }
    }
  }
}
