.social-share-links-comp {
  @extend .list-inline;

  > li {
    @extend .list-inline-item;
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;

    border: 1px solid $salt-box;
    border-radius: 50%;

    &:hover,
    &:active {
      color: darken($salt-box, 35%);
      text-decoration: none;

      border-color: darken($salt-box, 35%);
    }
  }
}
