.landing-page {
  .title {
    margin-bottom: 1em;

    font-weight: 600;
    font-size: 1.3em;

    @include media-breakpoint-up(xl) {
      margin-bottom: 1.5em;

      font-size: 1.5em;
    }
  }

  .tagline {
    font-size: 1em;
  }

  .wrapper {
    // max-width: 992px;
  }

  section {
    &.gap {
      margin-top: 2em;
      margin-bottom: 2em;
      padding-top: 1em;
      padding-bottom: 1em;

      @include media-breakpoint-up(lg) {
        margin-top: 3em;
        margin-bottom: 3em;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 5em;
        margin-bottom: 5em;
      }
    }

    > .title-container {
      @extend .container;
      max-width: 700px;
      margin-bottom: 2em;

      @include media-breakpoint-up(md) {
        text-align: center;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: 3em;
      }
    }
  }

  .hero {
    height: 420px;
    max-height: calc(100vh - $header-height-sm);

    color: white;

    background-image: url("../../../public/static/images/homepage/hero-576.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include media-breakpoint-up(md) {
      height: 674px;
      max-height: calc(100vh - $header-height-md);

      background-image: url("../../../public/static/images/homepage/hero-1440.jpg");
    }

    > .container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 3em;
      padding-bottom: 3em;

      text-align: center;

      .title-wrapper {
        padding: 1em;
      }

      @include media-breakpoint-up(lg) {
        align-items: flex-end;
        padding-top: 10em;
        padding-bottom: 10em;

        text-align: left;

        .title-wrapper {
          max-width: 540px;
          padding: 0;
        }
      }
    }
  }

  .headline {
    &.carousel {
      padding-top: 2.4em;
      padding-bottom: 2.4em;

      .carousel-indicators {
        margin-bottom: 0;
      }
    }
  }

  .h-div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;

    color: white;
    font-size: 0.6em;
    text-align: center;

    background-color: $corduroy;

    @include media-breakpoint-up(lg) {
      min-height: 85px;

      font-size: 0.75em;
    }

    @include media-breakpoint-up(xl) {
      min-height: 100px;

      font-size: 1em;
    }

    .title {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }

      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  .intro {
    > .wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 3em;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr;
      }

      img {
        display: none;
        width: 400px;
        height: auto;

        @include media-breakpoint-up(lg) {
          display: block;
          width: 500px;
        }

        @include media-breakpoint-up(xl) {
          width: 100%;
        }
      }
    }
  }

  .about {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 135px);
      gap: 1em;
      align-items: center;
      justify-content: center;
      width: auto;
      max-width: 100%;

      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 200px);
      }

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 216px);
      }

      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 309px);
        gap: 3em;
      }
    }

    a.text-link {
      display: block;
      padding: 0.3em 0;

      color: black;
      line-height: 1.3;
      text-align: center;

      &:hover,
      &:active,
      &:focus {
        color: black;
      }

      @include media-breakpoint-up(md) {
        line-height: 1.5;
      }

      @include media-breakpoint-up(lg) {
        padding: 4px 0;
      }
    }

    .figure-link {
      position: relative;

      display: block;

      width: 100%;
      height: 120px;
      overflow: hidden;

      @include media-breakpoint-up(sm) {
        height: 155px;
      }

      @include media-breakpoint-up(md) {
        height: 168px;
      }

      @include media-breakpoint-up(xl) {
        height: 241px;
      }

      figure {
        position: relative;

        width: 100%;
        height: 100%;
        margin: 0;

        img {
          filter: grayscale(0.5);

          transition: filter 0.15s ease-in-out;

          will-change: filter;
        }

        > figcaption {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;

          padding: 0.5em 4px;

          color: white;

          font-size: 0.85em;
          text-align: center;

          background-color: rgba($corduroy, 0.8);

          transition: background-color 0.15s ease-in-out;

          will-change: background-color;

          @include media-breakpoint-up(md) {
            padding: 0.7em 4px;

            font-size: 1em;
          }

          @include media-breakpoint-up(xl) {
            padding: 1em 4px;
          }
        }
      }

      &:hover,
      &:active {
        img {
          filter: grayscale(0);
        }

        figcaption {
          background-color: rgba($corduroy, 1);
        }
      }
    }
  }

  .athen-section {
    position: relative;

    padding-bottom: 1em;

    background-color: $athens-gray;

    > .fly {
      position: relative;

      padding: 3em 0;

      background-color: white;
      box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.1);
    }

    @include media-breakpoint-up(lg) {
      margin-top: 15em;
      padding-top: 10em;
      padding-bottom: 7em;

      > .fly {
        position: absolute;
        top: -10em;
        right: 0;
        left: 0;

        width: 100%;
        max-width: 800px;
        margin-right: auto;
        margin-left: auto;
        padding: 3em 1em;

        background-color: white;

        .grid-container {
          grid-template-columns: repeat(3, 220px);
        }
      }
    }
  }

  .qna {
    > .container {
      &.wrapper {
        max-width: 920px;
      }
    }
  }
}
