.blogs-page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  $content-side-gap: 1em;

  .blogs-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
    padding-bottom: $content-side-gap;
  }

  .popular-blogs-list {
    padding-top: $content-side-gap;

    font-size: 0.9em;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .header {
      margin-bottom: 1em;

      font-size: 1em;
    }

    .blog-link {
      .blog-body {
        .blog-title {
          font-size: 1em;
        }
      }
    }
  }

  .blog-link {
    display: flex;
    align-items: center;
    padding: 1em 0;

    @include media-breakpoint-up(xl) {
      align-items: flex-start;
    }

    .img-blog {
      display: block;
      max-width: 100%;
      height: auto;
      margin-right: 1em;
    }

    .blog-body {
      display: block;
      width: 100%;
    }

    ~ .blog-link {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr minmax(0, 220px);

    .blogs-list {
      padding-right: $content-side-gap;
      padding-bottom: 0;
    }

    .popular-blogs-list {
      min-height: 100%;
      padding-top: 0;
      padding-left: $content-side-gap;

      border-top: 0 none;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
