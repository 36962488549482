.blog-post-page {
  nav {
    display: block;

    a.link-back {
      > i {
        margin-right: 0.5em;
      }
    }
  }

  $content-side-gap: 2em;

  > .wrapper {
    display: block;
    max-width: 100%;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      align-items: flex-start;
      justify-content: center;
    }

    .blog-body {
      padding-bottom: $content-side-gap;
    }

    .blog-side {
      padding-top: $content-side-gap;

      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 250px);

      .blog-body {
        padding-right: $content-side-gap;
        padding-bottom: 0;
      }

      .blog-side {
        padding-top: 0;
        padding-left: $content-side-gap;

        border-top: 0 none;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  > .wrapper {
    .blog-body {
      max-width: 720px;
      margin: 0 auto;

      .img-wrapper,
      .blog-content {
        display: block;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }

      .img-wrapper {
        margin-bottom: 2em;
      }

      .blog-content {
        img {
          margin: 2em auto;
        }
      }

      hr {
        margin-top: 3em;
        margin-bottom: 3em;
      }
    }

    .blog-side {
      display: block;
      margin: 0 auto;

      text-align: center;

      @include media-breakpoint-up(xl) {
        text-align: left;
      }

      .author {
        .simple-expert-link-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .img-container {
            justify-content: start;
            margin-bottom: 0.75em;

            .avatar {
              max-height: 150px;
              margin: 0 auto;
            }
          }

          @include media-breakpoint-up(xl) {
            align-items: flex-start;
            justify-content: flex-start;
          }

          h2 {
            margin-bottom: 0.5em;

            font-size: 1em;
          }

          p {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
