.blog-card.card {
  display: block;

  text-decoration: none;

  transition: color 0.15s;

  will-change: color;

  &:hover,
  &:active {
    color: darken($salt-box, 40%);
    text-decoration: none;
  }

  .card-title {
  }

  .img-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 1em;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    img.img-blog {
      width: 100%;
      max-width: 100%;
      height: auto;
      max-height: 100%;

      @include media-breakpoint-up(md) {
      }
    }
  }
}
