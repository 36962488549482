// @import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

html,
body {
  margin: 0;
  padding: 0;

  font-size: 1rem;
  font-family: "Inter", Roboto, Arial, sans-serif;
}

.container-fluid {
  > .wrapper {
    // max-width: 1400px;
    margin: 0 auto;
  }
}

.btn {
  &:hover,
  &:focus {
    outline: 0 none;
    box-shadow: none;
  }

  &.btn-cta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 242px;
    min-height: 60px;
    padding: 0;

    font-size: 0.9rem;
    line-height: 1;
  }

  &.btn-link {
    outline: 0 none;
    box-shadow: none;

    &:hover,
    &:focus {
      color: darken($corduroy, 70%);
      text-decoration: none;
    }
  }
}

a.link {
  color: $corduroy;

  &:hover,
  &:active {
    color: darken($corduroy, 15%);
  }
}

div.auto-grid {
  &.service-links {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 1em;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }

    @include media-breakpoint-up(md) {
      gap: 1.5em;
    }

    @include media-breakpoint-up(xl) {
      gap: 2em;
    }
  }
}
